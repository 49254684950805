html,
body,
#root {
  margin: 0;
  padding: 0;
  min-height: 100%;
  display: flex;
  flex-basis: 100%;
  width: 100%;
}

#root {
  position: relative;
  padding: 0px;
}

body button:not(:disabled) {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

body label {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}
