.root {
  margin-top: 130px;
  position: relative; 
  height: auto;
  width: 100%;
  display: flex; 
}

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 650px;
  width: 100%;
  position: relative;
}


.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); /* Centers the button vertically */
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 20px;
  z-index: 2; /* Ensures the button is above the image/video */
}

.arrow:hover {
  background: rgba(0, 0, 0, 0.7); /* Darker on hover */
}

.arrow:first-child {
  left: 0;
}

.arrow:last-child {
  right: 0;
}


.backgroundImg{
  width: 100%;    
  height: 650px; 
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden; 
}

.backgroundMedia {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(20px); /* Adjust the blur value as desired */
  transform: scale(1.6); /* Slightly scale up to cover any transparent edges caused by the blur */
  z-index: 0; /* Place it behind the main content */
}

.media {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
}


