.root {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 130px;
  margin: 0 auto;
  align-items: flex-start;
  z-index: 999;
}
.topNavBar {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px; 
  height: 100px;
  background-color: #fff;
}
.symposiaStudio {
  color: #000;
  font-size: 36px;
  line-height: 75px;
  font-family: Pirata One, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-transform: uppercase;
  margin-right: 20px;
  position: relative;
  width: auto;
  margin-right: 10%;
  height: 60px;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-shadow: 0px 4px 4px #00000040;
  flex-direction: column;
  background-image: url('../../../../public/assets/BannerImg.JPEG');
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.streamerConsole {
  color: #414141;
  font-size: 16px;
  line-height: 75px;
  font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-transform: uppercase;
  position: relative;
  width: auto;
  margin-right: 20px;
  height: 50px;
  flex-direction: column;
  text-decoration: none;
  background-image: url('../../../../public/assets/BannerImg.JPEG');
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.browseGames {
  color: #414141;
  font-size: 16px;
  line-height: 75px;
  font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-transform: uppercase;
  position: relative;
  width: auto;
  margin-right: 20px;
  height: 50px;
  flex-direction: column;
  text-decoration: none;
  background-image: url('../../../../public/assets/BannerImg.JPEG');
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  vertical-align: top; 
}
.services {
  color: #414141;
  font-size: 16px;
  line-height: 75px;
  font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-transform: uppercase;
  position: relative;
  width: auto;
  margin-right: 20px;
  height: 50px;
  flex-direction: column;
  text-decoration: none;
  background-image: url('../../../../public/assets/BannerImg.JPEG');
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.examples {
  color: #414141;
  font-size: 16px;
  line-height: 75px;
  font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-transform: uppercase;
  position: relative;
  width: auto;
  margin-right: 10px;
  height: 50px;
  flex-direction: column;
  text-decoration: none;
  background-image: url('../../../../public/assets/BannerImg.JPEG');
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.contact {
  color: #414141;
  font-size: 16px;
  line-height: 75px;
  font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-transform: uppercase;
  margin-right: 20px;
  position: relative;
  width: auto;
  height: 50px;
  flex-direction: column;
  text-decoration: none;
  background-image: url('../../../../public/assets/BannerImg.JPEG');
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.symposiaStudio {
  display: flex;
  align-items: center;
  justify-content: center;
}
.browseGamesDropdown {
  position: relative;
  display: flex;
  justify-content: center;
  height: 50px;
  width: auto;
}

.streamerConsole, 
.browseGamesDropdown, 
.services, 
.examples, 
.contact {
    align-self: flex-end;
    margin-bottom: 10px;
    margin-right: 50px;
}

.browseGamesLink {
  text-decoration: none;
  color: #414141;
  font-size: 16px;
  line-height: 75px;
  font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif;
  text-transform: uppercase;
  background-image: url('../../../../public/assets/BannerImg.JPEG');
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f9f9f9;
  min-width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
  width: calc(100% - 32px);
}

.browseGamesDropdown:hover .dropdown-content {
  display: block;
}