.root {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #d9d9d9;
  margin: 0;
  padding: 0;
}
.rectangle1 {
  width: 100%;
  height: 259px;
  background-color: #d9d9d9;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.unnamed,
.unnamed2,
.unnamed3,
.unnamed4 {
  color: #414141;
  font-size: 18px;
  line-height: 27px;
  font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif;
  width: 72px;
  height: min-content;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.companyMetrics {
  width: 100%;
  height: 258px;
  background-image: url('../../../../public/assets/CompanyMetrics.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
