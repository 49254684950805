body {
    background-color: #0a0a0a;
    font-family: 'Orbitron', sans-serif;
    font-size: 1.0em;
  }
  
  .streamer-console-wrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100vh;
    background: radial-gradient(circle at top, #000, #000 70%, #020431 100%);
    box-sizing: border-box;
    overflow: auto;
  }
  
  .flex-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
  }
  
  input[type="text"] {
    padding: 10px;
    border: 1px solid #99cc00;
    border-radius: 4px;
    background-color: #000;
    color: #99cc00;
    flex-grow: 1;
  }
  
  button {
    padding: 8px 16px;
    margin-left: 10px;
    background: linear-gradient(90deg, #dd33ff 0%, #99cc00 100%);
    color: #000;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.0em;
    font-family: 'Orbitron', sans-serif;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    transition: background-color 0.3s ease;
    text-transform: uppercase;
  }

  .highlighted {
    /* Define the style when the button is highlighted (when tikTokConnected is true) */
    background: linear-gradient(90deg, #dd33ff 0%, #99cc00 100%);
  }
  
  .greyedOut {
    /* Define the style when the button is greyed out (when tikTokConnected is false) */
    background: grey;
    cursor: not-allowed; /* This will make the button look unclickable */
  }

  .greyedOut:hover {
    /* No hover change when the button is greyed out */
    background: grey;
  }

  #ids {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #99cc00;
    border-radius: 4px;
    background-color: #000;
    color: #99cc00;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.6);
  }

  .admin-console {
    margin-top: 20px;
    border: 1px solid #99cc00;
    padding: 20px;
    margin: 10px;
    border-radius: 4px;
    color: #99cc00;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.6);
  }
  
  
  button:hover {
    background: linear-gradient(90deg, #99cc00 0%, #dd33ff 100%);
  }
  
  .home-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 8px 16px;
    background: linear-gradient(90deg, #9900cc 0%, #dd33ff 100%);
    color: #000;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.2em;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    transition: background-color 0.3s ease;
    text-align: center;
  }
  
  .home-button:hover {
    background: linear-gradient(90deg, #dd33ff 0%, #9900cc 100%);
  }
  
  