
.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%; /* Adjust as needed */
  max-width: 1200px; /* Maximum width */
}

.row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px; /* Space between rows */
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 25%; /* Adjust for 3 columns */
}

.subContent {
  color: #999;
  font-size: 13px;
  line-height: 27px;
  font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  position: relative;
  /* width: 234px; /* Let the text flow naturally */
  flex-direction: column;
}

.subTitle {
  color: #414141;
  font-size: 18px;
  line-height: 21.6px;
  font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  /* width: 292px; /* Adjust as needed */
  height: 48px;
  flex-direction: column;
}

.root {
  position: relative;
  width: 100%;
  height: 881px;
  display: flex;
  flex-direction: column; /* Align children vertically */
  justify-content: center;
  align-items: center;
  background-color: #d9d9d9;
  padding: 20px;
}

.absolute-container {
  position: relative;
  width: 80%; /* Adjust as needed */
  max-width: 1200px; /* Maximum width */
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.rectangle5 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #d9d9d9;
}

.titleContainer {
  display: flex; /* Flex container to center the title */
  justify-content: center; /* Center the title horizontally */
  width: 100%; /* Take up the full width */
}

.title {
  color: #414141;
  font-size: 48px;
  line-height: 32.4px;
  font-family: Pirata One, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-align: center; /* Centers the text horizontally */
  margin-bottom: 60px;
}

