.root {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  position: relative;
  width: 100%;
  height: auto;
  align-items: flex-start;
  background-color: #fff;
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  overflow: hidden; 
}
