.root {
  width: 100%;
  height: 313px;
  background-color: #18191a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.columns {
  width: 80%; /* Adjust as needed to control the overall width */
  display: flex;
  justify-content: center; /* Center columns horizontally */
  align-items: center; /* Center columns vertically if needed */
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 60px;
  
}

.footline {
  width: 100%;
  display: flex;
  justify-content: center;  /* Horizontally center the children */
  align-items: center;      /* Vertically center the children */
  background-color: #18191a;
  padding: 20px 0;          /* Adds padding to the top and bottom */
  height: 100vh;
}

.subcontentList {
  list-style: none; /* Remove bullet points */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.subContent,
.subTitle {
  color: #fff;
  font-size: 14px;
  line-height: 16.2px;
  font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: fit-content;
  height: min-content;
  flex-direction: column;
}

.subTitle {
  font-size: 19px;
  line-height: 22.68px;
  margin-bottom: 10px;      
}

/* Adjust the order and grouping of the elements based on your design */

