/* .root {
  position: absolute;
  left: 0;
  top: 1653px;
  width: 100%;
  height: 767px;
  align-items: flex-start;
}
.aboutUs {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
}
.rectangle {
  position: absolute;
  left: 908px;
  top: 414px;
  width: 271px;
  height: 233px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0px 1px 4px 0px #0c0c0d1a;
}
.iMAGE {
  position: absolute;
  left: 1004px;
  top: 506px;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background-image: url('https://www.musicweek.com/cimages/584c561ad7823bba888d27f85d1dcd5a.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.rectangle2 {
  position: absolute;
  left: 611px;
  top: 414px;
  width: 271px;
  height: 233px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0px 1px 4px 0px #0c0c0d1a;
}
.iMAGE2 {
  position: absolute;
  left: 707px;
  top: 506px;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background-image: url('https://www.musicweek.com/cimages/584c561ad7823bba888d27f85d1dcd5a.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.rectangle3 {
  position: absolute;
  left: 315px;
  top: 414px;
  width: 271px;
  height: 233px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0px 1px 4px 0px #0c0c0d1a;
}
.iMAGE3 {
  position: absolute;
  left: 411px;
  top: 506px;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background-image: url('https://www.musicweek.com/cimages/584c561ad7823bba888d27f85d1dcd5a.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.rectangle4 {
  position: absolute;
  left: 24px;
  top: 414px;
  width: 271px;
  height: 233px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0px 1px 4px 0px #0c0c0d1a;
}
.iMAGE4 {
  position: absolute;
  left: 120px;
  top: 506px;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background-image:url('https://www.musicweek.com/cimages/584c561ad7823bba888d27f85d1dcd5a.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.intro {
  color: #414141;
  font-size: 32px;
  line-height: 35px;
  font-family: Play, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  position: absolute;
  left: 10.1667%;
  right: 7%;
  top: 17.6094%;
  bottom: 56.2433%;
  height: min-content;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.aboutUs2 {
  color: #414141;
  font-size: 48px;
  line-height: 32.4px;
  font-family: Pirata One, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  position: absolute;
  left: 37.5833%;
  right: 37.6667%;
  top: 7.5704%;
  bottom: 85.7394%;
  text-align: center;
  align-items: center;
  flex-direction: column;
} */
.root {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.rectangle,
.rectangle2,
.rectangle3 {
  position: relative;
  width: 22%; /* Adjust as needed */
  height: auto;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0px 1px 4px 0px #0c0c0d1a;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5%;
}
.iMAGE,
.iMAGE2,
.iMAGE3 {
  width: 80px; /* You can adjust as needed */
  height: 80px;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.iMAGE { background-image: url('https://www.musicweek.com/cimages/584c561ad7823bba888d27f85d1dcd5a.png'); }
.iMAGE2 { background-image: url('https://www.musicweek.com/cimages/584c561ad7823bba888d27f85d1dcd5a.png'); }
.iMAGE3 { background-image: url('https://www.musicweek.com/cimages/584c561ad7823bba888d27f85d1dcd5a.png'); }
.iMAGE4 { background-image: url('https://www.musicweek.com/cimages/584c561ad7823bba888d27f85d1dcd5a.png'); }
.aboutUs,
.intro,
.aboutUs2 {
  width: 100%;
  position: relative;
  padding: 2%;
}
.aboutUs {
  background-color: #fff;
}
.intro {
  color: #414141;
  font-size: 32px;
  line-height: 35px;
  font-family: Play, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif;
  position: relative;
  padding: 5% 10%;
  text-align: center;
}

.aboutUs2 {
  color: #414141;
  font-size: 48px;
  line-height: 32.4px;
  font-family: Pirata One, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif;
  position: relative;
  padding: 2% 5%;
  text-align: center;
}



